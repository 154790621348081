export default {
  "All": "All",
  "Home": "Home",
  "Product": "Product",
  "Use Cases": "Use Cases",
  "Collect Emails for Marketing Campaigns": "Collect Emails for Marketing Campaigns",
  "Collect Leads": "Collect Leads",
  "Feedback and Surveys": "Feedback and Surveys",
  "Announce Updates to Your Audience": "Announce Updates to Your Audience",
  "Provide Coupons & Offers": "Provide Coupons & Offers",
  "Templates": "Templates",
  "Blog": "Blog",
  "Pricing": "Pricing",
  "Contact": "Contact",
  "Sign in": "Sign in",
  "Sign up": "Sign up",
  "Categories": "Categories",
  "Get started now": "Get started now",
  "Onvocado logo": "Onvocado logo",
  "Engage your users": "Engage your users",
  "Support": "Support",
  "Integrations": "Integrations",
  "Terms & Conditions": "Terms & Conditions",
  "Privacy Policy": "Privacy Policy",
  "Join the Newsletter": "Join the Newsletter",
  "Get Latest News": "Get Latest News",
  "Follow Us": "Follow Us",
  "Facebook": "Facebook",
  "Instagram": "Instagram",
  "LinkedIn": "LinkedIn",
  "Made in Europe": "Made in Europe",
  "Illustrations from": "Illustrations from",
  "E-mail address": "E-mail address",
  "Subscribe": "Subscribe",
  "Related post": "Related post",
  "Read more": "Read more",
  "View blog post": "View blog post",
  "View support article": "View support article",
  "Use this template": "Use this template",
  "Written by": "Written by",
  "On this page": "On this page",
  "Monthly": "Monthly",
  "Annually": "Annually",
  "Language": "Language",
  "Save with annual plans": "Save 20% with annual plans",
  "search_placeholder": "Start your search, find your answers",
  "search_button": "Search",
  "search_results_for": "Search results for '{query}'",
  "no_results_heading": "No Results Found",
  "no_results_subtext": "We couldn’t find any results for '{query}'. Please try different keywords or check your spelling.",
  "thank_you_message": "Thank you,\nwe'll get in touch shortly!",
  "compare": "Compare",
  "hero": {
    "title": "Power up your site's \nengagement easily \nand code free!",
    "description": "Onvocado helps your users make that one last step towards converting while collecting valuable data for your upcoming marketing campaigns.",
    "start_free": "Start for free today",
    "templates_title": "Wide range of templates for every business need",
    "templates_subtitle": "Launch your setup in minutes.",
    "feature_1": "Customizable themes",
    "feature_2": "Optimized for conversion",
    "feature_3": "No coding skills required"
  },
  "editorPreview": {
    "title": "Need innovative features? We've got you covered.",
    "description": "Accelerate your workflow with fast editing, real-time dashboard, and instant analytics.",
    "slide_1_name": "Select",
    "slide_2_name": "Customize",
    "slide_3_name": "Publish",
    "slide_4_name": "Analyze",
    "slide_1_alt": "Template selection preview",
    "slide_2_alt": "Editor preview",
    "slide_3_alt": "Dashboard preview",
    "slide_4_alt": "Analytics preview"
  },
  "supportSection": {
    "zoneTitle": "Works Everywhere",
    "zoneDetails": "Onvocado integrates seamlessly with your current platforms, providing the tools you need.",
    "tryFree": "Try Onvocado for free",
    "integratesTitle": "Integrates with many",
    "integratesDescription": "Connect to the platforms you love. Our app integrates smoothly with Mailchimp, Zapier, ActiveCampaign, and others. Simplify your processes and boost efficiency effortlessly.",
    "logoWordPress": "Logo of WordPress",
    "logoMagento": "Logo of Magento",
    "logoSquarespace": "Logo of Squarespace",
    "logoWooCommerce": "Logo of WooCommerce",
    "logoShopify": "Logo of Shopify",
    "integrationActiveCampaign": "Logo of ActiveCampaign",
    "integrationMailchimp": "Logo of Mailchimp",
    "integrationWebhook": "Logo of Webhook",
    "integrationZapier": "Logo of Zapier",
    "integrationKlaviyo": "Logo of Klaviyo",
    "integrationSendfox": "Logo of Sendfox",
    "integrationBrevo": "Logo of Brevo",
    "integrationSendgrid": "Logo of Sendgrid",
    "integrationGetResponse": "Logo of GetResponse",
    "integrationAweber": "Logo of Aweber",
    "integrationOmnisend": "Logo of Omnisend"
  },
  "ctaSection": {
    "zoneTitle": "Discover the Power of Onvocado",
    "zoneSubtitle": "Engage and convert like never before",
    "zoneDetails": "Onvocado provides powerful tools to boost your engagement and conversions. Our platform integrates seamlessly with the apps you already use.",
    "buttonText": "Get Started with Onvocado",
    "freeText": "It's free!",
    "whoUses": "Who Uses Onvocado?"
  },
  "pageNotFound": {
    "title": "Page Not Found",
    "description": "The page you are looking for doesn't exist or has been moved.",
    "homeButton": "Back to Home",
    "homeButtonLabel": "Home"
  },
  "cookiePopup": {
    "message": "Cookies are used on this site to improve site navigation and provide the greatest user experience. We collect data on how you use our website to make it easier to use. By clicking accept, you agree to this.",
    "necessary": "Strictly Necessary Cookies",
    "statistics": "Statistics Cookies",
    "marketing": "Marketing Cookies",
    "cookiePolicy": "Cookie Policy",
    "acceptSelected": "Accept selected",
    "ok": "OK, got it"
  },
  "plans": {
    "tagline": {
      "basic": "Basic",
      "pro": "Most Popular",
      "agency": "Best Value"
    },
    "free": "Free",
    "perMonth": "per user, per month",
    "perYear": "per user, per year",
    "description": {
      "basic": "Basic features for personal projects.",
      "pro": "Advanced tools for growing businesses.",
      "agency": "Full suite of tools for large organizations."
    },
    "visitorsPerMonth": "Visitors/mo",
    "maxDomains": "Max. {count}",
    "domain": "domain",
    "domains": "domains",
    "unlimited": "Unlimited Campaigns",
    "maxCampaigns": "Max. {count} Campaigns",
    "features": {
      "detailed_analytics": "Detailed Analytics",
      "multi_step": "Multi-Step Forms",
      "a/b_testing": "A/B Testing",
      "no_watermark": "No Watermark",
      "priority_support": "Priority Support"
    },
    "tooltips": {
      "multi_step": "Capture more user data effortlessly, avoiding the hassle of long forms.",
      "a/b_testing": "Create multiple variants and split traffic among different designs to identify which layout and content perform best."
    },
    "basic_support": "Basic support",
    "getStarted": "Get started",
    "customPlanPrompt": "Interested in a custom plan?",
    "getInTouch": "Get in touch"
  },
  "clients": {
    "trustedBy": "Trusted by <b class='text-body-black'>the best</b>.",
    "alt": {
      "eddy": "Eddy's logo",
      "frank": "Studio Frank logo",
      "lusio": "Lusio Architects logo",
      "shares": "Shares logo",
      "wwf": "WWF logo",
      "kingservers": "Kingservers logo",
      "client3": "logo",
      "client4": "logo"
    }
  },
  "blogPost": {
    "featured": "Featured",
    "shareThisArticle": "Share this article",
    "aboutTheAuthor": "About the author",
    "discussion": "Discussion",
    "writeAComment": "Write a Comment",
    "noCommentsYet": "No comments yet. Be the first to comment!",
    "whatIsOnYourMind": "What’s on your mind?",
    "name": "Name",
    "comment": "Comment",
    "submitting": "Submitting...",
    "submitComment": "Submit Comment",
    "thanksForComment": "Thanks for your comment! 🙌",
    "commentReview": "It will appear as soon as our team gives it a quick check!"
  }
}
