
export default {
  name: "SupportSection",
  props: ['zone'],
  data() {
    return {
      logos: [
        {src: '/images/logos/wordpress_monotone_black.svg', altKey: 'supportSection.logoWordPress'},
        {src: '/images/logos/magento_monotone_black.svg', altKey: 'supportSection.logoMagento'},
        {src: '/images/logos/squarespace_monotone_black.svg', altKey: 'supportSection.logoSquarespace'},
        {src: '/images/logos/woocomerce_monotone_black.svg', altKey: 'supportSection.logoWooCommerce'},
        {src: '/images/logos/shopify_monotone_black.svg', altKey: 'supportSection.logoShopify'}
      ],
      integrationLogos: [
        { src: '/images/logos/integrations/activecampaign.svg', altKey: 'supportSection.integrationActiveCampaign', width: 516, height: 53 },
        { src: '/images/logos/integrations/mailchimp.svg', altKey: 'supportSection.integrationMailchimp', width: 185, height: 60 },
        { src: '/images/logos/integrations/webhook.svg', altKey: 'supportSection.integrationWebhook', width: 159, height: 44 },
        { src: '/images/logos/integrations/zapier.svg', altKey: 'supportSection.integrationZapier', width: 80, height: 40 },
        { src: '/images/logos/integrations/klaviyo.svg', altKey: 'supportSection.integrationKlaviyo', width: 160, height: 40 },
        { src: '/images/logos/integrations/sendfox.svg', altKey: 'supportSection.integrationSendfox', width: 150, height: 40 },
        { src: '/images/logos/integrations/brevo.svg', altKey: 'supportSection.integrationBrevo', width: 140, height: 40 },
        { src: '/images/logos/integrations/twilio-sendgrid.svg', altKey: 'supportSection.integrationSendgrid', width: 180, height: 50 },
        { src: '/images/logos/integrations/getresponse.svg', altKey: 'supportSection.integrationGetResponse', width: 200, height: 50 },
        { src: '/images/logos/integrations/aweber.svg', altKey: 'supportSection.integrationAweber', width: 170, height: 40 },
        { src: '/images/logos/integrations/omnisend.svg', altKey: 'supportSection.integrationOmnisend', width: 170, height: 40 },
        { src: '/images/logos/integrations/mailjet.svg', altKey: '', width: 170, height: 40 },
      ],
    };
  },
  computed: {
    firstRowLogos() {
      const halfLength = Math.ceil(this.integrationLogos.length / 2);
      return [...this.integrationLogos.slice(0, halfLength), ...this.integrationLogos.slice(0, halfLength)];
    },
    secondRowLogos() {
      const halfLength = Math.ceil(this.integrationLogos.length / 2);
      return [...this.integrationLogos.slice(halfLength), ...this.integrationLogos.slice(halfLength)];
    },
  },
};
